import {Component, Input} from '@angular/core';
import { MapLayer, MapLayerCategory } from './models';
import { HttpClient } from '@angular/common/http';
import { environment } from '@envs/environment';
import {Feature, Map } from 'ol';
import {ActivatedRoute, ActivationEnd, Router} from "@angular/router";
import TileLayer from 'ol/layer/Tile';
import TileWMS from "ol/source/TileWMS";
import { MapLayerService } from "@/_services/map-layer.service";
import VectorLayer from "ol/layer/Vector";
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {Extent} from "ol/extent";

@Component({
  standalone: false,
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent {
  http: HttpClient;
  public layers: MapLayer[];
  @Input() public showLayers : boolean = true;
  @Input() name: string = '';
  public map: Map | undefined;

  public isGoogleMapBasemap: boolean;
  public gm_layer: TileLayer<TileWMS> = new TileLayer({
    preload: Infinity,
    visible: false,
    source: new TileWMS({
      url: environment.mapproxyUrl,
      params: { 'LAYERS': 'gm_layer', 'TILED': true },
      transition: 0
    })
  });

  public isOsmBasemap: boolean;
  public loader: boolean = false;
  public loaderCount: number = 0;
  public osm_layer: TileLayer<TileWMS> = new TileLayer({
    preload: Infinity,
    source: new TileWMS({
      url: environment.mapproxyUrl,
      params: { 'LAYERS': 'osm', 'TILED': true },
      transition: 0
    })
  });

  private itemId: number;

  constructor( private readonly _http: HttpClient,
               private readonly route: ActivatedRoute,
               private readonly router: Router,
               private readonly _mapLayerService: MapLayerService) {
    this.http = _http;
    this.itemId = 0;
    this.layers = new Array<MapLayer>();
    this.isGoogleMapBasemap = false;
    this.isOsmBasemap = true;
  }

  drop(event: CdkDragDrop<string[]>) {
    this._mapLayerService.updateOrder(event.previousIndex, event.currentIndex);
  }

  toogleLayers() {
    this.showLayers = !this.showLayers;
  }

  toogleLayerCategories(layer: any) {
    layer.expanded = !layer.expanded;
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.itemId = params['itemId'];
    });
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.component == MapComponent) {
          if(this._mapLayerService.zoomToLayer != null) {
            this.zoomToArea(this._mapLayerService.zoomToLayer);
            this._mapLayerService.zoomToLayer = null;
          }
        }
      }
    });
  }

  public onMapReady(map: Map) {
    this.map = map;
    this.map.addLayer(this.gm_layer);
    this.map.addLayer(this.osm_layer);
    this._mapLayerService.layers.subscribe(
      layers => {
        this.layers = layers;
        if (this.map != undefined) {
          let self = this;
          this.map.setLayers([this.gm_layer, this.osm_layer]);
          this.layers.slice().reverse().forEach(function (layer: MapLayer) {
            if (layer.olLayer && self.map != undefined) {
              self.map.addLayer(layer.olLayer);
            }
          });
          setTimeout(function() {
            if(self._mapLayerService.zoomToLayer != null) {
              self.zoomToArea(self._mapLayerService.zoomToLayer);
              self._mapLayerService.zoomToLayer = null;
            }
          }, 200)
        }
      }
    );
    this._mapLayerService.event.subscribe(
      event => {
        if (event.target == '' || event.target == this.name) {
          if(event.action == 'zoomToFeature') {
            this.zoomToFeature(event.data.layerId, event.data.featureId)
          }
          if(event.action == 'zoomToLayer') {
            this.zoomToAreaByLayerId(event.data.layerId)
          }
          if(event.action == 'zoomToExtent') {
            this.zoomToExtent(event.data.extent)
          }
          if (event.action == 'showLoader') {
            this.loaderCount++;
            this.loader = true;
          }
          if (event.action == 'hideLoader') {
            this.loaderCount--;
            if (this.loaderCount <= 0) {
              this.loaderCount = 0;
              this.loader = false;
            }
          }
        }
      }
    );
  };

  public addLayerToMap(layer: MapLayer) {
    if (layer.olLayer) {
      this.layers?.push(layer);
      this.map?.addLayer(layer.olLayer);
    }
  }

  public removeLayer(layer: MapLayer) {
    let index = this.layers.indexOf(layer);
    if (layer.olLayer != undefined) {
      this.map?.removeLayer(layer.olLayer);
    }
    this.layers.splice(index, 1);
  }

  public zoomToArea(layer: MapLayer) {
    if (layer != undefined) {
      let extent: any;
      if (layer.olLayer instanceof VectorLayer) {
        extent = layer.olLayer?.getSource()?.getExtent();
      }
      if (layer.olLayer instanceof TileLayer) {
        extent = layer.olLayer?.getExtent();
      }
      let view = this.map?.getView();
      setTimeout(function() {
        if (view != undefined && extent != undefined) {
          view.fit(extent, {padding: [32, 32, 32, 32], duration: 800});
        }
      }, 200);
    }
  };

  public zoomToExtent(extent: Extent) {
    if (extent != undefined) {
      let view = this.map?.getView();
      setTimeout(function() {
        if (view != undefined && extent != undefined) {
          view.fit(extent, {padding: [32, 32, 32, 32], duration: 800});
        }
      }, 200);
    }
  };

  public zoomToAreaByLayerId(layerId: string) {
    let layer = this.layers.find(layer => layer.id == layerId);
    if (layer != undefined) {
      this.zoomToArea(layer)
    }
  }

  public zoomToFeature(layerId: string, featureId: number) {
    let layer = this.layers.find(layer => layer.id == layerId);
    if (layer != undefined) {
      if (layer.olLayer instanceof VectorLayer) {
        let source = layer.olLayer?.getSource();
        let feature = source?.getFeatureById(featureId);
        if (feature instanceof Feature) {
          let extent = feature?.getGeometry()?.getExtent();
          let view = this.map?.getView();
          setTimeout(function () {
            if (view != undefined && extent != undefined) {
              view.fit(extent, {padding: [32, 32, 32, 32], duration: 800});
            }
          }, 200);
        }
      }
    }
  }
  public toggleLayerVisibility(layer: MapLayer) {
    if (layer != undefined) {
      layer.visible = !layer.visible;
      layer.olLayer?.setVisible(layer.visible)
    }
  };

  public getStyle = function (cat: MapLayerCategory) {
    if (cat?.dataRangeStart != undefined && cat?.colorRangeStart && cat?.colorRangeEnd) {
      return "background: linear-gradient(90deg," + cat.colorRangeStart + ", "+cat.colorRangeEnd + "); border: none";
    }
    else if (cat.outlineColor) {
      return "background: " + cat.color + "; border: 1px solid " + cat.outlineColor;
    } else {
      return "background: " + cat.color + "; border: none";
    }
  };

  public getStyleForColorPicker = function (color: string) {
    if (color) {
      return "display:block; background: " + color + "; color:" + color;
    } else {
      return "display:none";
    }
  };

  public googleMapBasemap() {
    this.gm_layer?.setVisible(true);
    this.osm_layer?.setVisible(false);
    this.isGoogleMapBasemap = true;
    this.isOsmBasemap = false;
  }

  public osmBasemap() {
    this.gm_layer?.setVisible(false);
    this.osm_layer?.setVisible(true);
    this.isGoogleMapBasemap = false;
    this.isOsmBasemap = true;
  }

  public noBasemap() {
    this.gm_layer?.setVisible(false);
    this.osm_layer?.setVisible(false);
    this.isGoogleMapBasemap = false;
    this.isOsmBasemap = false;
  }
}
