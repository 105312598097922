<div class="input-group input-group-sm b-3" dropdown [ngClass]="{'input-text': type == 'text' }">
  <button dropdownToggle type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle" [ngClass]="{'disabled': disabled}" [disabled]="disabled">
    <span [hidden]="operator == 10">
      <span [hidden]="operator != 1">=</span>
      <span [hidden]="operator != 2">≠</span>
      <span [hidden]="operator != 3">&gt;</span>
      <span [hidden]="operator != 4">&ge;</span>
      <span [hidden]="operator != 5">&lt;</span>
      <span [hidden]="operator != 6">&le;</span>
      <span [hidden]="operator != 7"></span>
      <span [hidden]="operator != 8">not </span>
      <span [hidden]="operator != 9"></span>
      <span [hidden]="operator != 11">&isin; (</span>
      <span [hidden]="operator != 12">&notin; (</span>
    </span>
    <span class="caret"></span>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" [ngClass]="{disabled: disabled}">
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 1}" *ngIf="type=='number' || type=='date' || type=='text' " (click)="setOperator(1)">Equals</a></li>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 2}" *ngIf="type=='number' || type=='date' || type=='text' " (click)="setOperator(2)">Not equals</a></li>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 3}" *ngIf="type=='number'" (click)="setOperator(3)">Greater than</a></li>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 3}" *ngIf="type=='date'" (click)="setOperator(4)">from</a></li>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 4}" *ngIf="type=='number' || type=='numberlimited' " (click)="setOperator(4)">Greater than or equal to</a></li>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 5}" *ngIf="type=='number'" (click)="setOperator(5)">Less than</a>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 5}" *ngIf="type=='date'" (click)="setOperator(6)">to</a>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 6}" *ngIf="type=='number' || type=='numberlimited' " (click)="setOperator(6)">Less than or equal to</a>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 7}" *ngIf="type=='text'" (click)="setOperator(7)">Contains</a>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 8}" *ngIf="type=='text'" (click)="setOperator(8)">Not Contains</a>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 9}" *ngIf="type=='text'" (click)="setOperator(9)">Ends with</a>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 10}" *ngIf="type=='text'" (click)="setOperator(10)">Starts with</a>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 13}" *ngIf="type=='text'" (click)="setOperator(13)">Not Ends with</a>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 14}" *ngIf="type=='text'" (click)="setOperator(14)">Not Starts with</a>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 11}" *ngIf="type=='number' || type=='numberlimited' " (click)="setOperator(11)">Between</a>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 11}" *ngIf="type=='date'" (click)="setOperator(11)">Between</a>
    <li role="menuitem"><a class="dropdown-item" [ngClass]="{'active': operator == 12}" *ngIf="type=='number'" (click)="setOperator(12)">Not between</a>
    <li role="menuitem">
      <a class="dropdown-item" [ngClass]="{'active': operator == 12}" *ngIf="type=='date'" (click)="setOperator(12)">Not between</a>
    </li>
  </ul>
  <input type="text" [disabled]="disabled" *ngIf="type=='date'" [(ngModel)]="valuePrimary" placeholder="yyyy-mm-dd" class="input-date form-control form-control-sm" bsDatepicker>
  <input type="text" [disabled]="disabled" class="form-control" *ngIf="type=='text'" [(ngModel)]="valuePrimary">
  <input type="number" [disabled]="disabled" class="form-control" *ngIf="type=='number'|| type=='numberlimited' " [(ngModel)]="valuePrimary">
  <span class="input-group-text" *ngIf="(operator == 11 || operator == 12)">-</span>
  <input type="text" [disabled]="disabled" *ngIf="type=='date'  && (operator == 11 || operator == 12)" [(ngModel)]="valueSecondary" placeholder="yyyy-mm-dd" class="input-date form-control form-control-sm" bsDatepicker>
  <input type="text" [disabled]="disabled" class="form-control" *ngIf="(type=='text') && (operator == 11 || operator == 12)" [(ngModel)]="valueSecondary">
  <input type="number" [disabled]="disabled" class="form-control" *ngIf="(type=='number'|| type=='numberlimited' ) && (operator == 11 || operator == 12)" [(ngModel)]="valueSecondary">
  <!--  <span class="input-group-text" *ngIf="operator == 7 || operator == 10 || operator == 8">%</span>-->
  <span class="input-group-text" *ngIf="(operator == 11 || operator == 12)">)</span>
</div>
