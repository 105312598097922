import {LineString, Point} from "ol/geom";
import {Circle, Fill, Stroke, Style} from "ol/style";

export function handleGeometryStyle(_feature: any, properties: any) {
  let geometry = _feature.getGeometry();
  let geometryType = geometry?.getType();
  if (['Point'].includes(geometryType)) {
    let pointGeom = <Point>_feature.getGeometry();
    let coord = pointGeom.getCoordinates();
    let startPoint = coord;
    let endPoint = [coord[0] + 0.1, coord[1] + 0.1]; // Create a short line
    _feature.setGeometry(new LineString([startPoint, endPoint]));
    geometry = _feature.getGeometry();
    geometryType = geometry?.getType();
  }
  if (['LineString', 'MultiLineString'].includes(geometryType)) {
    _feature.setStyle(
      new Style({
        stroke: new Stroke({
          color: properties.lineColor,
          width: properties.lineWidth,
        })
      })
    );
  }
  else if (['Point', 'MultiPoint'].includes(geometryType)) {
    _feature.setStyle(
      new Style({
        image: new Circle({
          radius: properties.pointWidth,
          fill: new Fill({
            color: properties.pointColor,
          }),
          stroke: new Stroke({
            color: properties.pointOutlineColor,
            width: 1,
          })
        })
      })
    );
  }
  else if (['Polygon', 'MultiPolygon', 'GeometryCollection'].includes(geometryType)) {
    _feature.setStyle(
      new Style({
        fill: new Fill({
          color: properties.polygonColor,
        }),
        stroke: new Stroke({
          color: properties.polygonOutlineColor,
          width: properties.polygonOutlineWidth,
        }),
      })
    );
  }
}
