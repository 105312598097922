import { Component, NgZone, AfterViewInit, Output, Input, EventEmitter, ViewEncapsulation } from '@angular/core';
import { View, Feature, Map, Overlay } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { ScaleLine, defaults as DefaultControls } from 'ol/control';
import { defaults as DefaultInteractions } from 'ol/interaction';
import Projection from 'ol/proj/Projection';
import { get as GetProjection } from 'ol/proj'
import { Extent } from 'ol/extent';
import {Circle, Fill, Stroke, Style} from "ol/style";
import { TinyColor } from "@ctrl/tinycolor";
import { OlMapService } from "@/_services/ol-map.service";
import VectorLayer from "ol/layer/Vector";

@Component({
  standalone: false,
  selector: 'app-ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OlMapComponent implements AfterViewInit {
  @Input()
  center!: Coordinate;
  @Input() zoom!: number;
  @Input() name: string = '';
  view!: View;
  projection!: Projection;
  extent: Extent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
  Map!: Map;
  @Output() mapReady = new EventEmitter<Map>();
  selected : any = null;
  originalStyle : any = null;
  private container: HTMLElement | null | undefined;
  private content: HTMLElement | null | undefined;
  private closer: HTMLElement | null | undefined;
  private overlay: Overlay | undefined;
  constructor(private readonly zone: NgZone, private readonly _olMapService: OlMapService) {
    this._olMapService.event.subscribe(
      (event: any) => {
        if (event.target == '' || event.target == this.name) {
          if(event.action == 'updateSize') {
            let _self = this;
            setTimeout(function(){
              _self.Map.updateSize();
            })
          }
          if(event.action == 'selectFeature') {
            let layer: any;
            this.Map.getLayers().forEach(function (lyr) {
              if (event.data.layerId == lyr.get('layerId')) {
                layer = lyr;
              }
            });
            let source = layer.getSource();
            let f = source.getFeatureById(event.data.featureId);
            this.deselectFeatures();
            this.selectFeature(f);
          }
        }
      }
    );
  }

  ngAfterViewInit(): void {
    if (!this.Map) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => this.initMap())
        this.zone.run(() => {});
      })
    }
    setTimeout(() => this.mapReady.emit(this.Map));
  }

  private initMap(): void {
    this.container = document.getElementById('popup');
    this.content = document.getElementById('popup-content');
    this.closer = document.getElementById('popup-closer');
    this.overlay = new Overlay({
      element: this.container as HTMLElement,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    let projection = GetProjection('EPSG:3857')
    if (projection) {
      this.projection = projection;
    }
    let _self = this;
    this.view = new View({
      center: this.center,
      zoom: this.zoom,
      maxZoom: 20,
      projection: this.projection,
      extent: this.extent
    });

    let mapControls = DefaultControls({
      attribution : false,
      rotate: false,
    });
    mapControls.push(new ScaleLine({}))

    this.Map = new Map({
      target: 'map',
      view: this.view,
      interactions: DefaultInteractions({pinchRotate: false, altShiftDragRotate: false}),
      overlays: [this.overlay],
      controls: mapControls,
    });

    this.Map.on('pointermove', function(e){
      let pixel = _self.Map.getEventPixel(e.originalEvent);
      let hit = _self.Map.hasFeatureAtPixel(pixel);
      _self.Map.getViewport().style.cursor = hit ? 'pointer' : '';
    });

    this.Map.on('click', function (e) {
      _self.deselectFeatures();
      _self.Map.forEachFeatureAtPixel(e.pixel, function (f: any) {
        _self.selectFeature(f);
        let popup = f.get('popup');
        if (popup) {
          if(popup.startsWith('{')) {
            let data = JSON.parse(popup);
            let text = "<table class='popup-table'>"
            for (let key in data) {
              text += "<tr><td>" + key + "</td><td>" + data[key] + "</td></tr>";
            }
            text += "</table>"
            _self.content!.innerHTML = text;
          }
          else {
            _self.content!.innerHTML = '' + popup +'';
          }
        }
        _self.overlay?.setPosition(e.coordinate);
        return true;
      }, {
        hitTolerance: 2
      });
    });
  }

  private deselectFeatures() {
    this.overlay?.setPosition(undefined);
    this.content!.innerHTML = '';
    this.selected = null;
    this.Map.getLayers().forEach(function(layer) {
      if(layer instanceof VectorLayer) {
        let layerSource = layer.getSource();
        if (layerSource != null) {
          layerSource.getFeatures().forEach(function(feature: any) {
            if(feature.get('originalStyle')) {
              feature.setStyle(feature.get('originalStyle'))
              feature.unset('originalStyle')
            }
          })
        }
      }
    })
  }
  private selectFeature(f: Feature) {
    this.selected = f;
    f.set('originalStyle', f.getStyle())
    this.originalStyle = f.getStyle();
    let fill = this.originalStyle.getFill()?.getColor();
    let stroke = this.originalStyle.getStroke()?.getColor();
    let width = this.originalStyle.getStroke()?.getWidth();
    let image = this.originalStyle.getImage();
    if (image != null) {
      fill = image.getFill()?.getColor();
      stroke = image.getStroke()?.getColor();
      let radius = image.getRadius();
      f.setStyle([
        new Style({
          image: new Circle({
            radius: radius,
            fill: new Fill({
              color: (fill !== undefined) ? this.invertColor(fill).toString(): '#0f0',
            }),
            stroke: new Stroke({
              color: (stroke !== undefined) ? new TinyColor(stroke).toString(): '#0f0',
              width: 1,
            })
          })
        })])
    }
    else {
      f.setStyle([
        new Style({
          stroke: new Stroke({
            color: (stroke !== undefined) ? new TinyColor(stroke).toString(): '#0f0',
            width: width*2,
          }),
          zIndex: 0
        }),
        new Style({
          fill: new Fill({
            color: (fill !== undefined) ? this.invertColor(fill).toString(): '#0f0',
          }),
          stroke: new Stroke({
            color: (stroke !== undefined) ? this.invertColor(stroke).toString(): '#0f0',
            width: width,
          }),
          zIndex: 1
        })]);
    }

  }
  private invertColor(color: string) {
    const rgb = new TinyColor(color).toRgb()
    rgb.r = Math.max(0, Math.min(255, 255 - rgb.r))
    rgb.g = Math.max(0, Math.min(255, 255 - rgb.g))
    rgb.b = Math.max(0, Math.min(255, 255 - rgb.b))
    return new TinyColor(rgb)
  }
}
