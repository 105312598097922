import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { MaterialModule } from './material.module';

import { appRoutingModule } from './app.routing';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { LoginComponent } from './login';
import { IconsModule } from './icons.module';
import { AssetsComponent } from './assets';
import { AnalysisComponent } from './analysis';
import { MapComponent } from './map';
import { OlMapComponent } from './_components/ol-map';
import { MainMenuComponent } from "./_components/main-menu";
import { DashboardUploadComponent } from '@/_components/dashboard-upload';
import { ForgotPasswordComponent } from './forgot-password';
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ResetPasswordComponent } from "./reset-password";
import { GridComponent } from './_components/grid';
import { AnalysisImportComponent } from '@/_components/analysis-import';
import { InputDialogComponent } from '@/_components/input-dialog';
import { AssetsImportComponent } from '@/_components/assets-import';
import { FileSizePipe } from './_helpers/file.size.pipe';
import { MapLayerService } from './_services/map-layer.service';
import { LookupService } from './_services/lookup.service';
import { InfoDialogComponent } from '@/_components/info-dialog';
import { EventMetricsComponent } from '@/_components/event-metrics';
import { AssetMetricsComponent } from '@/_components/asset-metrics';
import { AnalysisMetricsComponent } from '@/_components/analysis-metrics';
import { LookupComponent } from './_components/lookup';
import { UsersComponent } from './users';
import { ReportsComponent } from './reports';
import { FilterInputComponent } from "@/_components/filter-input";
import { EventsComponent } from './events';
import { BsDatepickerConfig, BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { RouteReuseStrategy } from "@angular/router";
import { CustomRouteReuseStrategy } from "@/_helpers/custom.route.reuse.strategy";
import { NgxBootstrapMultiselectModule } from "ngx-bootstrap-multiselect";
import { FilterPanelComponent } from "@/_components/filter-panel";
import { OlMapService } from "@/_services/ol-map.service";
import { SatelliteImagesComponent } from "@/satellite-images";
import { ReportDetailsComponent } from '@/report-details';
import { CreateReportComponent } from '@/create-report';
import { DataDialogComponent } from '@/_components/data-dialog';
import { AnalysisImportRasterComponent } from '@/_components/analysis-import-raster';
import { SateliteImportComponent } from '@/_components/satelite-import';
import { VariablesComponent } from '@/variables';
import { CreateVariableComponent } from '@/_components/create-variable';
import { ConfirmDialogComponent } from '@/_components/confirm-dialog';
import { VariableDetailsDialogComponent } from "@/_components/variable-details-dialog";
import { ReportParametersDialogComponent } from "@/_components/report-parameters-dialog";
import { LookupAsTextComponent } from "@/_components/lookup-as-text";
import { FilterAsTextComponent } from "@/_components/filter-as-text";
import { FilterAsDateComponent } from "@/_components/filter-as-date";
import { GridConfigDialog } from '@/_components/grid-config-dialog/grid-config-dialog.component';
import { ReportCreateComponent } from "@/report/create";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataLayerCreateComponent } from "@/data-layer/create/data-layer-create.component";
import { NgArrayPipesModule } from 'ngx-pipes';
import { DataLayerComponent } from './data-layer';
import { DataLayerParametersDialogComponent } from './_components/data-layer-parameters-dialog';
import { NewReportParametersDialogComponent } from './_components/new-report-parameters-dialog';
import { NgWizardModule, NgWizardConfig, THEME } from "ng-wizard";
import {LookupNgSelectComponent} from "@/_components/lookup-ngselect";
export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: 'YYYY-MM-DD',
    containerClass: 'theme-default'
  });
}
const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default,
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AssetsComponent,
    AnalysisComponent,
    MapComponent,
    MainMenuComponent,
    OlMapComponent,
    DashboardUploadComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AnalysisImportComponent,
    AnalysisImportRasterComponent,
    AssetsImportComponent,
    InputDialogComponent,
    InfoDialogComponent,
    DataDialogComponent,
    GridComponent,
    GridConfigDialog,
    EventMetricsComponent,
    AssetMetricsComponent,
    AnalysisMetricsComponent,
    UsersComponent,
    LookupComponent,
    ReportsComponent,
    ReportDetailsComponent,
    FilterInputComponent,
    EventsComponent,
    FilterPanelComponent,
    SatelliteImagesComponent,
    FilterPanelComponent,
    CreateReportComponent,
    SateliteImportComponent,
    VariablesComponent,
    CreateVariableComponent,
    ConfirmDialogComponent,
    VariableDetailsDialogComponent,
    ReportParametersDialogComponent,
    DataLayerParametersDialogComponent,
    NewReportParametersDialogComponent,
    LookupAsTextComponent,
    FilterAsTextComponent,
    FilterAsDateComponent,
    ReportCreateComponent,
    DataLayerCreateComponent,
    DataLayerComponent,
    LookupNgSelectComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    IconsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    MaterialModule,
    FormsModule,
    appRoutingModule,
    BsDropdownModule,
    BsDatepickerModule,
    NgxBootstrapMultiselectModule,
    FileSizePipe,
    NgSelectModule,
    NgArrayPipesModule,
    [NgWizardModule.forRoot(ngWizardConfig)],
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    MapLayerService,
    OlMapService,
    LookupService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
