<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-2">
      <mat-icon>{{data.icon}}</mat-icon>
    </div>
    <div class="col-10">
      <span class="mat-dialog-message">{{data.message}}</span>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()" cdkFocusInitial>Cancel</button>
  <div style="min-width:20px;" ></div>
  <button mat-button [mat-dialog-close]="true">Ok</button>
</div>
