<div class="screen">
  <div class="d-flex flex-row align-items-center justify-content-between pt-2 pb-2 mb-2 border-bottom">
    <h1 class="h4 tab-header">Assets</h1>
    <button (click)="openUpload()" class="upload-btn btn btn-sm btn-secondary">Upload assets</button>
    <span>
      View:
    </span>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="viewType" hideSingleSelectionIndicator="true" class="view-toggle">
      <mat-button-toggle value="table" class="view-toggle-button" (click)="setViewType('table')">Table</mat-button-toggle>
      <mat-button-toggle value="map"  class="view-toggle-button" (click)="setViewType('map', true)">Map</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="d-flex flex-row align-items-center">
    <asset-metrics class="w-100" [filter]="filter"></asset-metrics>
  </div>
  <div class="h-100 mt-1 d-flex data-grid">
    <app-filter-panel [config]="filterConfig" (filterEvent)="filter = $event"></app-filter-panel>
    <app-grid class="w-100" [data]="items" [config]="gridConfig" [service]="_assetsService" [filter]="filter" [hidden]="viewType!='table'"></app-grid>
    <app-map class="w-100 h-100" [showLayers]="true" [hidden]="viewType!='map'" name="assets"></app-map>
  </div>
</div>

