<div class="d-flex flex-row align-items-center pt-2 pb-2 mb-2 border-bottom">
  <h1 class="h4 header">Create Report</h1>
  <div class="name-input">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" [style.width.%]="100">
      <mat-label>Report Name</mat-label>
      <input matInput [(ngModel)]="reportName" required>
    </mat-form-field>
  </div>
</div>
<ng-wizard [config]="wizardConfig">
    <ng-wizard-step [title]="'Layer types'">
        <table class="table-layer-types table table-sm" [ngClass]="{'table-striped': !loadingDataLayers, 'table-hover': !loadingDataLayers}">
          <thead>
            <tr>
              <th class="w-40"></th>
              <th>Layer name</th>
              <th class="text-center w-160">Group by asset</th>
              <th class="text-center w-160">Show event details</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="loadingDataLayers">
              <tr *ngFor="let i of [].constructor(10)">
                <td class="align-middle" *ngFor="let i of [].constructor(4)"><ngx-skeleton-loader count="1" [theme]="{margin: 0, display: 'block', height: '21px'}"></ngx-skeleton-loader></td>
              </tr>
            </ng-container>
            <ng-container *ngIf="!loadingDataLayers">
              <tr *ngFor="let layerType of dataLayers" [ngClass]="{'selected': layerType.selected}">
                <td><input type="checkbox" [(ngModel)]="layerType.selected" (change)="updateLayerSelected(layerType)"></td>
                <td (click)="toggleLayer(layerType)" (keydown)="toggleLayer(layerType)">{{layerType.name}}</td>
                <td class="text-center"><input type="radio" value="0" [name]="'layerType-' + layerType.guid" [(ngModel)]="layerType.mode" [disabled]="!layerType.canBeAssetView" (change)="selectLayerMode(layerType)" (click)="selectLayerMode(layerType)" (keydown)="selectLayerMode(layerType)"></td>
                <td class="text-center"><input type="radio" value="1" [name]="'layerType-' + layerType.guid" [(ngModel)]="layerType.mode" [disabled]="!layerType.canBeEventView" (change)="selectLayerMode(layerType)" (click)="selectLayerMode(layerType)" (keydown)="selectLayerMode(layerType)"></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div class="actions">
          <button class="btn btn-sm btn-primary btn-next" [disabled]="!isAnyLayerSelected" (click)="goToSelectFilters()">Next</button>
        </div>
    </ng-wizard-step>
    <ng-wizard-step [title]="'Filters'">
      <ul class="selected-data-layers-list">
        @if(loadingReadyLayers) {
          <li class="selected-data-layer" *ngFor="let i of [].constructor(selectedDataLayersCount)">
            <div class="dataLayer-namebar mb-1">
              <ngx-skeleton-loader count="1" [theme]="{margin: 0, display: 'block', height: '27px', width: '200px'}"></ngx-skeleton-loader>
            </div>
            <ul class="selected-filters-list">
              <li *ngFor="let i of [].constructor(4)" class="selected-item">
                <ngx-skeleton-loader count="1" [theme]="{margin: 0, display: 'block', height: '31px', width: '200px'}"></ngx-skeleton-loader>
              </li>
            </ul>
          </li>
        }
        @if(!loadingReadyLayers) {
          @for (dataLayer of dataLayers; track dataLayer.guid) {
            <li *ngIf="dataLayer.selected" class="selected-data-layer">
              <div class="dataLayer-namebar">
                <h6 class="label">{{dataLayer.name}}</h6>
                <div class="form-check form-switch ms-1" *ngIf="dataLayer.checkReady">
                  <input class="form-check-input" type="checkbox" [id]="dataLayer.id + '_requestNew'" [(ngModel)]="dataLayer.requestNew" [checked]="dataLayer.readyLayers == undefined" [disabled]="dataLayer.readyLayers == undefined">
                  <label class="form-check-label" [for]="dataLayer.id + '_requestNew'">Request new data</label>
                </div>
              </div>
              <div class="alert alert-warning center-block" *ngIf="dataLayer.requestNew">
                <i-feather name="alert-triangle"></i-feather> You are about to request a new report generation. Warning, preparing this report might take a few days, we will inform you when the report is ready for you to view. If you have not already purchased the source geospatial data required to generated your requested report, the Spottitt team will contact you about purchasing the required data. Many thanks support&#64;spottitt.com.
              </div>
              <ul class="selected-filters-list" *ngIf="dataLayer.requestNew || !dataLayer.checkReady">
                <ng-container *ngFor="let filter of dataLayer.availableFilters">
                  <li *ngIf="!filter.type.includes('hidden')" class="selected-item">
                    <span class="form-label form-label-sm m-1 ms-0" [innerHTML]="filter.title"></span>
                    <app-filter-input *ngIf="filter.type == 'string'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="text"></app-filter-input>
                    <app-filter-input *ngIf="filter.type == 'number'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="number"></app-filter-input>
                    <app-filter-input *ngIf="filter.type == 'numberlimited'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="numberlimited"></app-filter-input>
                    <app-filter-input *ngIf="filter.type == 'date'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="date"></app-filter-input>
                    <app-lookup [noWidthLimit]="true" *ngIf="filter.type == 'lookup-single'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="single" [disabled]="filter.disabled"></app-lookup>
                    <app-lookup [noWidthLimit]="true" *ngIf="filter.type == 'lookup-multi-unused'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="multi" [disabled]="filter.disabled"></app-lookup>
                    <app-lookup-ngselect (change)="onLookupChange(filter)" [noWidthLimit]="true" *ngIf="filter.type == 'lookup-multi'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="multi" [disabled]="filter.disabled"></app-lookup-ngselect>
                    <app-lookup [noWidthLimit]="true" *ngIf="filter.type == 'lookup-single-nonnull'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="singlenn" [disabled]="filter.disabled"></app-lookup>
                    <div *ngIf="filter.type == 'pass_or_compare' && dataLayer.mode == '0' && dataLayer.comparativeViewColumns != null" class="pass-or-compare">
                      <app-lookup [name]="'PASSORCOMPARE'" mode="singlenn" [(ngModel)]="filter.dateMode" [noWidthLimit]="true" (change)="validateFilters()"></app-lookup>
                      <div>
                        <div class="dates">
                          <label class="form-label form-label-sm m-1 ms-0" [for]="filter.id + '_group'">By Pass A: </label>
                          <app-lookup [name]="'GROUP'" mode="singlenn" [(ngModel)]="filter.group" [noWidthLimit]="true" [id]="filter.id + '_group'" (change)="validateFilters()"></app-lookup>
                          <label class="form-label form-label-sm m-1" [for]="filter.id + '_date'">or by Date: </label>
                          <app-filter-input type="date" [(ngModel)]="filter.value" [id]="filter.id + '_date'" (change)="validateFilters()"></app-filter-input>
                        </div>
                        <ng-container *ngIf="filter.dateMode == 1">
                          <div class="dates">
                            <label class="form-label form-label-sm m-1 ms-0" [for]="filter.id + '_group2'">By Pass B: </label>
                            <app-lookup [name]="'GROUP'" mode="singlenn" [(ngModel)]="filter.group2" [noWidthLimit]="true" [id]="filter.id + '_group2'" (change)="validateFilters()"></app-lookup>
                            <label class="form-label form-label-sm m-1" [for]="filter.id + '_date2'">or by Date: </label>
                            <app-filter-input type="date" [(ngModel)]="filter.value2" [id]="filter.id + '_date2'" (change)="validateFilters()"></app-filter-input>
                          </div>
                        </ng-container>
                      </div>
                      <span class="form-label form-label-sm m-1"></span>
                      <div class="invalid-error" *ngIf="filter.invalidDates">
                        <i-feather name="alert-triangle"></i-feather>
                        <span>Please enter dates.</span>
                      </div>
                    </div>
                    <div *ngIf="filter.type == 'pass_or_compare' && (dataLayer.mode == '1' || dataLayer.comparativeViewColumns == null )" class="pass-or-compare">
                      <label class="form-label form-label-sm m-1 ms-0" [for]="filter.id + '_group'">by Pass:</label>
                      <app-lookup [name]="'GROUP'" mode="singlenn" [(ngModel)]="filter.group" [noWidthLimit]="true" [id]="filter.id + '_group'"></app-lookup>
                      <label class="form-label form-label-sm m-1" [for]="filter.id + '_date'">or by Date: </label>
                      <app-filter-input type="date" [(ngModel)]="filter.value" [id]="filter.id + '_date'"></app-filter-input>
                    </div>
                    <span class="form-label form-label-sm m-1" [innerHTML]="filter.postfix"></span>
                    <div class="invalid-error" *ngIf="filter.invalid">
                      <i-feather name="alert-triangle"></i-feather>
                      <span>Please choose at least one option.</span>
                    </div>
                  </li>
                </ng-container>
              </ul>
              <ul class="selected-filters-list" *ngIf="!dataLayer.requestNew && dataLayer.checkReady && dataLayer.readyLayers.length > 0">
                <li>
                  <label class="form-label form-label-sm m-1 ms-0" for="readyLayer_{{dataLayer.id}}">Use pre-generated data</label>
                  <ngx-bootstrap-multiselect id="readyLayer_{{dataLayer.id}}" [options]="dataLayer.readyLayers" [settings]="selectReadyLayersLookupSettings" [(ngModel)]="dataLayer.ReadyLayerId"></ngx-bootstrap-multiselect>
                </li>
                <ng-container *ngFor="let filter of dataLayer.readyFilters">
                  <li *ngIf="!filter.type.includes('hidden')" class="selected-item">
                    <span class="form-label form-label-sm m-1 ms-0" [innerHTML]="filter.title"></span>
                    <app-filter-input *ngIf="filter.type == 'string'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="text"></app-filter-input>
                    <app-filter-input *ngIf="filter.type == 'number'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="number"></app-filter-input>
                    <app-filter-input *ngIf="filter.type == 'numberlimited'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="numberlimited"></app-filter-input>
                    <app-filter-input *ngIf="filter.type == 'date'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="date"></app-filter-input>
                    <app-lookup [noWidthLimit]="true" *ngIf="filter.type == 'lookup-single'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="single" [disabled]="filter.disabled"></app-lookup>
                    <app-lookup [noWidthLimit]="true" *ngIf="filter.type == 'lookup-multi'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="multi" [disabled]="filter.disabled"></app-lookup>
                    <app-lookup [noWidthLimit]="true" *ngIf="filter.type == 'lookup-single-nonnull'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="singlenn" [disabled]="filter.disabled"></app-lookup>
                    <span class="form-label form-label-sm m-1" [innerHTML]="filter.postfix"></span>
                  </li>
                </ng-container>
              </ul>
            </li>
          }
        }
      </ul>
      <div class="actions">
        <button class="btn btn-sm btn-primary btn-prev" (click)="showPreviousStep($event)">Prev</button>
        <button class="btn btn-sm btn-primary btn-next" (click)="goToSelectColumns()" [disabled]="!isFiltersValid">Next</button>
      </div>
    </ng-wizard-step>
    <ng-wizard-step [title]="'Columns'">
      <div class="alert alert-info center-block">
        <i-feather name="info"></i-feather> Here you can choose which columns to include in report. Use drag-and-drop to change order. Column order and visibility can also be adjusted after report creation.
      </div>
      <table class="table table-sm table-striped table-hover">
        <thead>
        <tr>
          <th class="w-40">Show/Hide</th>
          <th>Column name</th>
          <th>Source layer</th>
        </tr>
        </thead>
        <tbody cdkDropList (cdkDropListDropped)="dropColumn($event)">
          @for (column of selectedColumns; track column.parentName + column.field) {
            <tr cdkDrag>
              <td><input type="checkbox" [(ngModel)]="column.selected"></td>
              <td [innerHTML]="column.title"></td>
              <td>{{column.parentName}}</td>
            </tr>
          }
        </tbody>
      </table>
      <div class="actions">
        <button class="btn btn-sm btn-primary btn-prev" (click)="showPreviousStep($event)">Prev</button>
        <button class="btn btn-sm btn-primary btn-next" (click)="goToSummary()">Next</button>
      </div>
    </ng-wizard-step>
    <ng-wizard-step [title]="'Summary / Preview'">
      <h3>{{reportName}}</h3>
      <div class="gridPreview">
        <app-grid [data]="fakeData" [config]="previewGridConfig"></app-grid>
      </div>
      <div class="alert alert-warning center-block" *ngIf="showPrepareDataWarning">
        <i-feather name="alert-triangle"></i-feather> You are about to request a new report generation. Warning, preparing this report might take a few days, we will inform you when the report is ready for you to view. If you have not already purchased the source geospatial data required to generated your requested report, the Spottitt team will contact you about purchasing the required data. Many thanks support&#64;spottitt.com
      </div>
      <div class="alert alert-info center-block">
        <i-feather name="info"></i-feather>  Column order and visibility can also be adjusted after report creation.
      </div>
      <div class="actions">
        <button class="btn btn-sm btn-primary btn-prev" (click)="showPreviousStep($event)">Prev</button>
        <button class="btn btn-sm btn-primary btn-next" (click)="create()" [disabled]="createLoader">
          <span *ngIf="createLoader" class="spinner-border spinner-border-sm mr-1"></span>
          Create
        </button>
      </div>
    </ng-wizard-step>
</ng-wizard>
