import {Component, Input, forwardRef, Output, EventEmitter} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  standalone: false,
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterInputComponent),
      multi: true
    }
  ]
})

export class FilterInputComponent implements ControlValueAccessor {
  public _selectedValue: FilterValue | undefined;
  @Input() type: string = "";
  @Input() postfix: string = "";
  @Input() disabled: boolean = false;
  @Output() change = new EventEmitter<any>();
  public operator: number = 1;
  public operatorSymbols: Dictionary<string> = {
    1: "=",
    2: "≠",
    3: "&gt;",
    4: "&ge;",
    5: "&lt;",
    6: "&le;",
    7: "&isin;",
    8: "&notin;",
    9: "A...",
    10: "...A",
    11: "&isin;",
    12: "&notin;",
    13: "<s>A...</s>",
    14: "<s>...A</s>",
    15: "&empty;",
    16: "≠&empty;",
  }
  public availableOperators: any = [
    {
      description: "Equals",
      operatorType: 1,
      availableForInputType: ['number','text','date']
    },
    {
      description: "Not equals",
      operatorType: 2,
      availableForInputType: ['number','text','date']
    },
    {
      description: "Greater than",
      operatorType: 3,
      availableForInputType: ['number']
    },
    {
      description: "Greater than or equal to",
      operatorType: 4,
      availableForInputType: ['number','numberlimited']
    },
    {
      description: "from",
      operatorType: 4,
      availableForInputType: ['date']
    },
    {
      description: "Less than",
      operatorType: 5,
      availableForInputType: ['number']
    },
    {
      description: "Less than or equal to",
      operatorType: 6,
      availableForInputType: ['number','numberlimited']
    },
    {
      description: "to",
      operatorType: 6,
      availableForInputType: ['date']
    },
    {
      description: "Contains",
      operatorType: 7,
      availableForInputType: ['text']
    },
    {
      description: "Not Contains",
      operatorType: 8,
      availableForInputType: ['text']
    },
    {
      description: "Ends with",
      operatorType: 9,
      availableForInputType: ['text']
    },
    {
      description: "Starts with",
      operatorType: 10,
      availableForInputType: ['text']
    },
    {
      description: "Not Ends with",
      operatorType: 13,
      availableForInputType: ['text']
    },
    {
      description: "Not Starts with",
      operatorType: 14,
      availableForInputType: ['text']
    },
    {
      description: "Between",
      operatorType: 11,
      availableForInputType: ['number','numberlimited','date']
    },
    {
      description: "Not between",
      operatorType: 12,
      availableForInputType: ['number','date']
    },
    {
      description: "Is empty",
      operatorType: 15,
      availableForInputType: ['number','numberlimited','date','text']
    },
    {
      description: "Is not empty",
      operatorType: 16,
      availableForInputType: ['number','numberlimited','date','text']
    },
  ];

  public _valuePrimary: any;
  public _valueSecondary: any;

  get selectedValue() {
    return this._selectedValue;
  }

  set selectedValue(val) {
    if (val != undefined) {
      this._selectedValue = val;
      this.valuePrimary = this._selectedValue.valuePrimary;
      this.valueSecondary = this._selectedValue.valueSecondary;
      this.operator = this._selectedValue.operator;
      this.propagateChange(this._selectedValue);
    }
    this.change.emit();
  }

  get valuePrimary() {
    return this._valuePrimary;
  }
  set valuePrimary(val: any) {
    this._valuePrimary = val;
    if (this._selectedValue != undefined) {
      this._selectedValue.valuePrimary = undefined;
      if (this.type == "number" && (val === "" || val === null)) {
        this._selectedValue.valuePrimary = null;
      }
      else if (this.type == "number") {
        this._selectedValue.valuePrimary = parseFloat(this.valuePrimary);
      }
      else if (this.type == "date" && val != "" && val instanceof Date) {
        this._selectedValue.valuePrimary = (new Date(Date.UTC(this.valuePrimary.getFullYear(), this.valuePrimary.getMonth(), this.valuePrimary.getDate(), 0, 0, 0))).toISOString();
      }
      else {
        this._selectedValue.valuePrimary = this.valuePrimary;
      }
    }
    this.change.emit();
    this.propagateChange(this._selectedValue);
  }

  get valueSecondary() {
    return this._valueSecondary;
  }

  set valueSecondary(val: any) {
    this._valueSecondary = val;
    if (this._selectedValue != undefined) {
      this._selectedValue.valueSecondary = undefined;
      if (this.type == "number" && val == "") {
        this._selectedValue.valueSecondary = null;
      }
      else if (this.type == "number") {
        this._selectedValue.valueSecondary = parseFloat(this.valueSecondary);
      }
      else if (this.type == "date" && val != "" && val instanceof Date) {
        this._selectedValue.valueSecondary = (new Date(Date.UTC(this.valueSecondary.getFullYear(), this.valueSecondary.getMonth(), this.valueSecondary.getDate(), 0, 0, 0))).toISOString();
      }
      else {
        this._selectedValue.valueSecondary = this._valueSecondary;
      }
    }
    this.change.emit();
    this.propagateChange(this._selectedValue);
  }

  setOperator(operator: number) {
    this.operator = operator;
    if (this._selectedValue != undefined) {
      this._selectedValue.operator = this.operator;
    }
    this.propagateChange(this._selectedValue);
  }

  writeValue(value: any) {
    if (value == undefined) {
      value = {
        operator: this.getDefaultOperator()
      };
    }
    this.selectedValue = value;
  }

  propagateChange = (_: any) => { };
  onTouched = (_: any) => { };

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  getDefaultOperator(): number {
    if (this.type == "text") {
      return 7;
    }
    return 1;
  }
}
class FilterValue {
  operator: number = 1
  valuePrimary: number | string | undefined | null
  valueSecondary: number | string | undefined | null
}

interface Dictionary<T> {
  [key: string]: T
}

